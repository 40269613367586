<template>
  <v-row>
    <v-col
      v-for="(card, index) in cards"
      :key="index"
      sm="6"
      lg="4"
      :offset-lg="index === 0 ? 2 : 0"
    >
      <v-hover v-slot="{ hover }">
        <v-card
          rounded="xl"
          :elevation="hover ? '6' : ''"
          @click="$emit('select-cursus', card.cursus)"
        >
          <v-card-text>
            <v-img
              :src="require(`@/assets/${card.picture}.svg`)"
              height="200"
              contain
            ></v-img>
          </v-card-text>

          <v-card-title class="justify-center pt-0">{{ card.title }}</v-card-title>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CursusEnr',

  data: () => ({
    cards: [
      { cursus: 'logo', title: 'Logopédie', picture: 'undraw_typewriter_i8xd' },
      { cursus: 'kine', title: 'Kinésithérapie', picture: 'undraw_doctors_hwty' },
    ],
  }),
};
</script>
