<template>
  <v-progress-linear
    v-if="isLoading"
    color="primary"
    indeterminate
  ></v-progress-linear>

  <v-container v-else class="my-12">
    <div class="text-center">
      <h1 class="text-h4">{{ pageTitle }}</h1>

      <template v-if="selectedCursus">
        <h2 class="text-subtitle-1">
          <template v-if="formType !== 'enr'">
            {{ selectedCursus.name }}, {{ selectedCursus.est_name}}
          </template>

          <template v-else>
            {{ selectedCursus === 'logo' ? 'Logopédie' : 'Kinésithérapie' }}
          </template>
        </h2>

        <v-btn
          text
          color="primary"
          x-small
          @click="getData(); selectedCursus = null"
        >
          Retour à la liste des cursus
        </v-btn>
      </template>

      <h2
        v-else-if="formIsOpen"
        class="text-subtitle-1"
      >
        Attention, une seule inscription est autorisée pour l'ensemble des cursus.
      </h2>
    </div>

    <component
      :is="activeComponent"
      @select-cursus="selectedCursus = $event"
      class="mt-6"
      :cursusId="selectedCursus ? selectedCursus.id : null"
      :cursus="cursus"
      :instanceType="formType"
      :instance="formType === 'enr' ? instance : null"
      :selectedCursus="formType === 'enr' ? selectedCursus : null"
    ></component>
  </v-container>
</template>

<script>
import CursusClosed from '@/components/guest/CursusClosed.vue';
import CursusDahuEnf from '@/components/guest/CursusDahuEnf.vue';
import CursusEnr from '@/components/guest/CursusEnr.vue';
import RegistrationCard from '@/components/guest/RegistrationCard.vue';

export default {
  name: 'GuestAll',

  components: {
    'cursus-closed': CursusClosed,
    'cursus-dahu-enf': CursusDahuEnf,
    'cursus-enr': CursusEnr,
    'registration-card': RegistrationCard,
  },

  data: () => ({
    selectedCursus: null,
    isLoading: true,
    cursus: [],
    formIsOpen: true,
    instance: {},
  }),

  created() {
    this.getData();
  },

  computed: {
    activeComponent() {
      if (!this.formIsOpen) return 'cursus-closed';
      if (this.selectedCursus) return 'registration-card';
      if (this.formType === 'enr') return 'cursus-enr';
      return 'cursus-dahu-enf';
    },

    pageTitle() {
      if (this.formType === 'dahu') return 'Etudiant Hors UE - Inscription';
      if (this.formType === 'enf') return 'Etudiant Non Finançable Externe - Inscription';
      return 'Etudiant Non Résident - Inscription';
    },

    formType() {
      return this.$route.path.substring(1);
    },
  },

  methods: {
    async getData() {
      try {
        this.isLoading = true;
        const response = await this.$http.get(`guest/getData.php?type=${this.formType}`);
        if (this.formType === 'enr') this.instance = response.data;
        else this.cursus = response.data;
      } catch (error) {
        if ([403, 404].includes(error.response?.status)) this.formIsOpen = false;
        else this.$router.push({ name: 'ErrorUnexpected' });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
